import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import './Intro.scss';

const Intro = ({
  title,
  image,
  information,
  cta,
  onBegin,
}) => {
  return (
    <div className="intro">
      <div className="intro-image">
        <img src={image} alt={title} />
      </div>
      <div className="content">
        <h1>{ title }</h1>
        <p>{ information }</p>
        <Button size="large" onPress={onBegin}>{ cta }</Button>
      </div>
    </div>
  )
};

Intro.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  information: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
  onBegin: PropTypes.func.isRequired
};

export default Intro;
