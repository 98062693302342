import React from 'react';
import PropTypes from 'prop-types';
import './QuestionProgress.scss';

const QuestionProgress = ({
  counter,
  total,
}) => {
  return (
    <div className="question-progress">
      <span style={{ width: `${Math.ceil((counter/total) * 100)}%`}} />
    </div>
  );
};

QuestionProgress.propTypes = {
  counter: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
};

export default QuestionProgress;
