import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import WorldVisionLogo from '../components/WorldVisionLogo/WorldVisionLogo';
import GoogleTagManager from '../components/GoogleTagManager/GoogleTagManager';
import PageFooter from '../components/PageFooter/PageFooter';
import './layouts.scss';

const StandardLayout = ({
  children,
  footerAddress,
  footerCopyright,
  footerPrivacyPolicyLabel,
  footerPrivacyPolicyUrl,
  footerTermsAndConditionsLabel,
  footerTermsAndConditionsUrl,
}) => {
  return (
    <>
      <div className="quiz-container quiz-container--standard">
        <header className="header">
          <WorldVisionLogo useDarkVersion />
        </header>
        <main>{ children }</main>      
      </div>
      <PageFooter 
        copyright={footerCopyright}
        address={footerAddress}
        privacyPolicyUrl={footerPrivacyPolicyUrl}
        privacyPolicyLabel={footerPrivacyPolicyLabel}
        termsAndConditionsUrl={footerTermsAndConditionsUrl}
        termsAndConditionsLabel={footerTermsAndConditionsLabel}
      />
      <GoogleTagManager
        gtmId={R.pathOr(null, [], process.env.GATSBY_SUPPORT_OFFICE_GTM_ID)}
      />
    </>
  );
};

StandardLayout.propTypes = {
  children: PropTypes.node.isRequired,
  footerAddress: PropTypes.string.isRequired,
  footerCopyright: PropTypes.string.isRequired,
  footerPrivacyPolicyLabel: PropTypes.string.isRequired,
  footerPrivacyPolicyUrl: PropTypes.string.isRequired,
  footerTermsAndConditionsLabel: PropTypes.string.isRequired,
  footerTermsAndConditionsUrl: PropTypes.string.isRequired,
};

export default StandardLayout;
