/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import * as R from 'ramda';

function SEO({
  title, description, author, langKey, meta,
}) {
  let pageMeta = [
    {
      name: 'description',
      content: description,
    },
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:description',
      content: description,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      name: 'twitter:creator',
      content: author,
    },
    {
      name: 'twitter:title',
      content: title,
    },
    {
      name: 'twitter:description',
      content: description,
    },
  ].concat(meta);

  if (R.length(R.filter(R.propEq('name', 'twitter:card'), pageMeta)) === 0) {
    pageMeta = pageMeta.concat([{
      name: 'twitter:card',
      content: 'summary',
    }]);
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: langKey,
      }}
      title={title}
      meta={pageMeta}
    />
  );
}
 
SEO.defaultProps = {
  meta: [],
  description: '',
};
 
SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  langKey: PropTypes.string.isRequired,
  meta: PropTypes.arrayOf(PropTypes.object), 
};
 
export default SEO;
 