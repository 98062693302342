import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import Button from '../Button/Button';
import './LeadForm.scss';

const LeadForm = ({
  onCompletedForm,
  quizResult,
  title,
  subtitle,
  firstNameLabel,
  surnameLabel,
  emailAddressLabel,
  phoneNumberLabel,
  privacyOptInMessage,
  skipCta,
  submitCta,
  mandatoryFields,
  fieldError,
  submissionError,
  formImageUrl,
  formImageAlt,
  formValuePropImageUrl,
  formValuePropImageAlt,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoadingRecaptcha, setIsLoadingRecaptcha] = useState(true);
  const [hasFormSubmitError, setHasFormSubmitError] = useState(false);
  const RECAPTCHA_SITE_KEY = process.env.GATSBY_RECAPTCHA_SITEKEY;
  const REPLACE_SUBSTR = '{FIELD_LABEL}';

  const onSubmit = async data => {
    const payload = {
      EmailAddress: data.emailAddress,
      Name: `${data.firstName} ${data.lastName}`,
      CustomFields: [
        {
          Key: 'Telefono',
          Value: data.phoneNumber,
        },
        {
          Key: 'MktConsent',
          Value: data.optIn ? 'Yes' : 'No',
        },
        {
          Key: 'QuizResult',
          Value: quizResult,
        },
      ],
      Resubscribe: true,
      RestartSubscriptionBasedAutoresponders: true,
      ConsentToTrack: 'No',
    };

    try {
      setIsSubmitting(true);
      setHasFormSubmitError(false);
      await window.grecaptcha.ready(async () => {
        const recaptchaToken = await window.grecaptcha.execute(
          RECAPTCHA_SITE_KEY,
          { action: 'submit' },
        );
        const res = await fetch('/.netlify/functions/subscribe', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ...payload, recaptchaToken }),
        });
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        onCompletedForm();
      });
    } catch (err) {
      console.error(err);
      setHasFormSubmitError(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.addEventListener('load', () => {
      window.grecaptcha.ready(() => {
        setIsLoadingRecaptcha(false);
      });
    });
    script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`;
    document.body.appendChild(script);
  }, [RECAPTCHA_SITE_KEY]);

  return (
    <div className="lead-form">
      <header>
        <h1>
          <span>{title}</span>
        </h1>
        <h2>{subtitle}</h2>

        {formValuePropImageUrl != null && (
          <img src={formValuePropImageUrl} alt={formValuePropImageAlt} />  
        )}

        <p className="mandatory_fields">{mandatoryFields}</p>
        <img src={formImageUrl} alt={formImageAlt}/>
      </header>

      <form className="lead-form__form" onSubmit={handleSubmit(onSubmit)}>
        <label
          className={`lead-form__label${
            errors.firstName ? ' lead-form__label--error' : ''
          }`}
        >
          <span>{firstNameLabel} *</span>
          <input
            aria-label={firstNameLabel}
            maxLength="200"
            placeholder=""
            type="text"
            className="lead-form__input"
            {...register('firstName', { required: true })}
          />
          {errors.firstName && (
            <span className="lead-form__error">
              {fieldError.replace(REPLACE_SUBSTR, firstNameLabel)}
            </span>
          )}
        </label>

        <label
          className={`lead-form__label${
            errors.lastName ? ' lead-form__label--error' : ''
          }`}
        >
          <span>{surnameLabel} *</span>
          <input
            aria-label={surnameLabel}
            id="lastName"
            maxLength="200"
            name="lastName"
            placeholder=""
            type="text"
            className="lead-form__input"
            {...register('lastName', { required: true })}
          />
          {errors.lastName && (
            <span className="lead-form__error">
              {fieldError.replace(REPLACE_SUBSTR, surnameLabel)}
            </span>
          )}
        </label>

        <label
          className={`lead-form__label${
            errors.emailAddress ? ' lead-form__label--error' : ''
          }`}
        >
          <span>{emailAddressLabel} *</span>
          <input
            autoComplete="Email"
            aria-label={emailAddressLabel}
            maxLength="200"
            placeholder=""
            type="email"
            className="lead-form__input"
            {...register('emailAddress', {
              required: true,
              pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i,
            })}
          />
          {errors.emailAddress && (
            <span className="lead-form__error">
              {fieldError.replace(REPLACE_SUBSTR, emailAddressLabel)}
            </span>
          )}
        </label>

        <label
          className={`lead-form__label${
            errors.phoneNumber ? ' lead-form__label--error' : ''
          }`}
        >
          <span>{phoneNumberLabel}</span>
          <input
            aria-label={phoneNumberLabel}
            id="phoneNumber"
            maxLength="200"
            name="phoneNumber"
            placeholder="e.g. 612 345 678"
            type="tel"
            className="lead-form__input"
            {...register('phoneNumber', { pattern: /^[+]?[0-9\s]*$/i })}
          />
          {errors.phoneNumber && (
            <span className="lead-form__error">
              {fieldError.replace(REPLACE_SUBSTR, phoneNumberLabel)}
            </span>
          )}
        </label>

        <label className={`lead-form__checkbox`}>
          <span>{privacyOptInMessage}</span>
          <input
            aria-label="Opt in"
            id="optIn"
            name="optIn"
            type="checkbox"
            className="lead-form__input"
            {...register('optIn')}
          />
        </label>

        <div className="lead-form__actions">
          <button
            className="lead-form__submit size-large"
            type="submit"
            disabled={isLoadingRecaptcha || isSubmitting}
          >
            {isSubmitting ? 'Sending...' : submitCta}
          </button>
          {hasFormSubmitError && <p>{submissionError}</p>}
          <Button
            onPress={() => {
              onCompletedForm(false);
            }}
            theme="underlined"
          >
            {skipCta}
          </Button>
        </div>
      </form>
    </div>
  );
};

LeadForm.propTypes = {
  onCompletedForm: PropTypes.func.isRequired,
  quizResult: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  firstNameLabel: PropTypes.string.isRequired,
  surnameLabel: PropTypes.string.isRequired,
  emailAddressLabel: PropTypes.string.isRequired,
  phoneNumberLabel: PropTypes.string.isRequired,
  privacyOptInMessage: PropTypes.string.isRequired,
  skipCta: PropTypes.string.isRequired,
  submitCta: PropTypes.string.isRequired,
  mandatoryFields: PropTypes.string.isRequired,
  fieldError: PropTypes.string.isRequired,
  submissionError: PropTypes.string.isRequired,
  formImageUrl: PropTypes.string.isRequired,
  formImageAlt: PropTypes.string,
  formValuePropImageUrl: PropTypes.string,
  formValuePropImageAlt: PropTypes.string,
};

LeadForm.defaultProps = {
  formImageAlt: '',
  formValuePropImageUrl: null,
  formValuePropImageAlt: '',
}

export default LeadForm;
