import React from 'react';
import PropTypes from 'prop-types';
import './BannerHeader.scss';

const BannerHeader = ({ children, className = '' }) => {
  return (
    <div className={`banner-header ${className}`}>
      <span>{children}</span>
    </div>
  );
};

BannerHeader.propTypes = {
  className: PropTypes.string,
};

BannerHeader.defaultProps = {
  className: '',
};

export default BannerHeader;
