import React, { useState } from 'react';
import { graphql } from 'gatsby';
import * as R from 'ramda';
import Helmet from 'react-helmet';
import Quiz from '../components/Quiz/Quiz';
import Result from '../components/Result/Result';
import Intro from '../components/Intro/Intro';
import LeadForm from '../components/LeadForm/LeadForm';
import StandardLayout from '../layouts/Standard';
import Seo from '../components/SEO/SEO';

const shuffleArray = array => {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

const IndexPage = ({ data }) => {
  const quizContent = data.kontentItemQuiz.elements;
  const quizQuestions = quizContent.questions.value.map(question => {
    return {
      question: question.elements.question.value,
      image: question.elements.image.value[0].url,
      answers: shuffleArray(
        question.elements.answers.value.map(answer => {
          return {
            type: answer.elements.result.value[0].elements.code.value,
            content: answer.elements.answer.value,
          };
        }),
      ),
    };
  });
  const [counter, setCounter] = useState(0);
  const [questionId, setQuestionId] = useState(1);
  const [question, setQuestion] = useState(quizQuestions[0]);
  const [answerOptions, setAnswerOptions] = useState(quizQuestions[0].answers);
  const [answer, setAnswer] = useState('');
  const [answersCount, setAnswersCount] = useState({});
  const [result, setResult] = useState('');
  const [shownIntro, setShownIntro] = useState(false);
  const [completedLeadGeneration, setCompletedLeadGeneration] = useState(false);
  const [sentEmail, setSentEmail] = useState(false);

  const seoTitle = `${R.pathOr('', ['kontentItemQuiz', 'elements', 'name', 'value'], data)} | ${R.pathOr('', ['kontentItemQuiz', 'elements', 'quiz_author', 'value'], data)}`;
  const seoDescription = R.pathOr('', ['kontentItemQuiz', 'elements', 'description', 'value'], data);
  const seoImage = R.pathOr('', ['kontentItemQuiz', 'elements', 'quiz_image', 'value', 0, 'url'], data);
  const seoAuthor = R.pathOr('', ['kontentItemQuiz', 'elements', 'quiz_author', 'value'], data);
  const langKey = process.env.GATSBY_LANG_KEY;

  const meta = [];
  if (seoImage) {
    meta.push({
      property: 'og:image',
      content: seoImage,
    });
    meta.push({
      property: 'og:image:width',
      content: 900,
    });
    meta.push({
      property: 'og:image:height',
      content: 900,
    });
    meta.push({
      name: 'twitter:card',
      content: 'summary_large_image',
    });
  }

  /**
   * Helper methods
   **/
  const handleAnswerSelected = event => {
    setUserAnswer(event.currentTarget.value);

    if (questionId < quizQuestions.length) {
      setTimeout(() => setNextQuestion(), 300);
    } else {
      const maxResultLetter = Object.entries(answersCount).reduce(
        (resultTemp, [key, value]) => {
          if (value > resultTemp.count) {
            return { letter: key, count: value };
          }
          return resultTemp;
        },
        { letter: 'A', count: 0 },
      ).letter;
      setTimeout(() => setResult(maxResultLetter), 300);
    }
  };

  const setUserAnswer = answer => {
    setAnswersCount({
      ...answersCount,
      [answer]: (answersCount[answer] || 0) + 1,
    });
    setAnswer(answer);
  };

  const setNextQuestion = () => {
    setCounter(counter + 1);
    setQuestionId(questionId + 1);
    setQuestion(quizQuestions[counter + 1]);
    setAnswerOptions(quizQuestions[counter + 1].answers);
    setAnswer('');
  };

  const getQuizResultData = () => {
    const processedResults = quizContent.results.value.map(result => {
      return {
        code: result.elements.code.value,
        dishDescription: result.elements.description.value,
        dishName: result.elements.title.value,
        dishImage: result.elements.photo.value[0].url,
      };
    });
    return processedResults.find(v => v.code === result);
  };

  /**
   * Render methods
   **/

  const renderQuiz = () => {
    return (
      <Quiz
        answer={answer}
        answerOptions={answerOptions}
        questionId={questionId}
        question={question}
        questionTotal={quizQuestions.length}
        onAnswerSelected={handleAnswerSelected}
        questionCountTpl={quizContent.question_count.value}
      />
    );
  };

  const renderResult = () => {
    return (
      <Result
        quizResult={getQuizResultData()}
        sentEmail={sentEmail}
        title={quizContent.results__title.value}
        shareTitle={quizContent.results__share_title.value}
        finalInstructions={quizContent.results__final_instructions.value}
        onBack={() => {
          setCompletedLeadGeneration(false);
        }}
        formValuePropImageUrl={R.pathOr(null, ['form__value_prop_image', 'value', 0, 'url'], quizContent)}
        formValuePropImageAlt={R.pathOr('', ['form__value_prop_image', 'value', 0, 'description'], quizContent)}
        formReAsk={R.pathOr('no', ['results__ask_lead_gen_again', 'value', 0, 'codename'], quizContent) === 'yes'}
        formReAskTitle={quizContent.results__lead_gen_title.value}
        formReAskMessage={quizContent.results__lead_gen_message.value}
        formReAskCTA={quizContent.results__lead_gen_cta.value}
      />
    );
  };

  const renderIntro = () => {
    return (
      <Intro
        title={R.pathOr(
          '',
          ['kontentItemQuiz', 'elements', 'introduction__title', 'value'],
          data,
        )}
        image={R.pathOr(
          '',
          [
            'kontentItemQuiz',
            'elements',
            'introduction__image',
            'value',
            0,
            'url',
          ],
          data,
        )}
        information={R.pathOr(
          '',
          [
            'kontentItemQuiz',
            'elements',
            'introduction__quiz_information',
            'value',
          ],
          data,
        )}
        cta={R.pathOr(
          '',
          [
            'kontentItemQuiz',
            'elements',
            'introduction__start_quiz_cta',
            'value',
          ],
          data,
        )}
        onBegin={() => {
          setShownIntro(true);
        }}
      />
    );
  };

  const renderLeadForm = () => {
    return (
      <LeadForm
        quizResult={result}
        onCompletedForm={(sentEmail = true) => {
          setCompletedLeadGeneration(true);
          setSentEmail(sentEmail);
        }}
        title={quizContent.form__title.value}
        subtitle={quizContent.form__sub_title.value}
        firstNameLabel={quizContent.form__first_name_label.value}
        surnameLabel={quizContent.form__surname_label.value}
        emailAddressLabel={quizContent.form__email_address_label.value}
        phoneNumberLabel={quizContent.form__phone_number_label.value}
        privacyOptInMessage={quizContent.form__privacy_opt_in_message.value}
        skipCta={quizContent.form__skip_cta.value}
        submitCta={quizContent.form__submit_cta.value}
        mandatoryFields={quizContent.form__mandatory_fields.value}
        fieldError={quizContent.form__field_error.value}
        submissionError={quizContent.form__submission_error.value}
        formImageUrl={quizContent.form__image.value[0].url}
        formImageAlt={R.pathOr('', ['form__image', 'value', 0, 'description'], quizContent)}
        formValuePropImageUrl={R.pathOr(null, ['form__value_prop_image', 'value', 0, 'url'], quizContent)}
        formValuePropImageAlt={R.pathOr('', ['form__value_prop_image', 'value', 0, 'description'], quizContent)}
      />
    );
  };

  return (
    <StandardLayout
      footerAddress={quizContent.footer__address.value}
      footerCopyright={quizContent.footer__copyright.value}
      footerPrivacyPolicyLabel={quizContent.footer__privacy_policy_label.value}
      footerPrivacyPolicyUrl={quizContent.footer__privacy_policy_url.value}
      footerTermsAndConditionsLabel={quizContent.footer__terms_and_conditions_label.value}
      footerTermsAndConditionsUrl={quizContent.footer__terms_and_conditions_url.value}
    >
      <Helmet>
        <link rel="icon" type="image/png" href="/images/wvi-icon.png" sizes="16x16" />
      </Helmet>
      <Seo
        title={seoTitle}
        description={seoDescription}
        langKey={langKey}
        meta={meta}
        author={seoAuthor}
      />
      {!shownIntro && renderIntro()}
      {shownIntro && !result && renderQuiz()}
      {shownIntro && result && !completedLeadGeneration && renderLeadForm()}
      {shownIntro && result && completedLeadGeneration && renderResult()}
    </StandardLayout>
  );
};

export default IndexPage;

export const query = graphql`
  query QuizQuery($lang: String, $quizId: String) {
    kontentItemQuiz(system: { id: { eq: $quizId }, language: { eq: $lang } }) {
      id
      elements {
        description {
          value
        }
        footer__address {
          value
        }
        footer__copyright {
          value
        }
        footer__privacy_policy_label {
          value
        }
        footer__privacy_policy_url {
          value
        }
        footer__terms_and_conditions_label {
          value
        }
        footer__terms_and_conditions_url {
          value
        }
        form__email_address_label {
          value
        }
        form__field_error {
          value
        }
        form__first_name_label {
          value
        }
        form__phone_number_label {
          value
        }
        form__mandatory_fields {
          value
        }
        form__privacy_opt_in_message {
          value
        }
        form__skip_cta {
          value
        }
        form__sub_title {
          value
        }
        form__submission_error {
          value
        }
        form__submit_cta {
          value
        }
        form__surname_label {
          value
        }
        form__title {
          value
        }
        introduction__quiz_information {
          value
        }
        introduction__start_quiz_cta {
          value
        }
        introduction__title {
          value
        }
        name {
          value
        }
        question_count {
          value
        }
        quiz_author {
          value
        }
        results__final_instructions {
          value
        }
        results__share_title {
          value
        }
        results__title {
          value
        }
        results__ask_lead_gen_again {
          value {
            codename
          }
        }
        results__lead_gen_title {
          value
        }
        results__lead_gen_message {
          value
        }
        results__lead_gen_cta {
          value
        }
        form__value_prop_image {
          value {
            url
            description
            width
            type
            size
            name
            height
          }
        }
        form__image {
          value {
            url
            description
            width
            type
            size
            name
            height
          }
        }
        introduction__image {
          value {
            description
            height
            name
            size
            type
            url
            width
          }
        }
        quiz_image {
          value {
            description
            height
            name
            size
            type
            url
            width
          }
        }
        results {
          value {
            ... on kontent_item_quiz__result {
              id
              elements {
                code {
                  value
                }
                description {
                  value
                }
                photo {
                  value {
                    description
                    height
                    name
                    size
                    type
                    url
                    width
                  }
                }
                title {
                  value
                }
              }
            }
          }
        }
        questions {
          value {
            ... on kontent_item_quiz__question {
              id
              elements {
                answers {
                  value {
                    ... on kontent_item_quiz__answer {
                      id
                      elements {
                        answer {
                          value
                        }
                        result {
                          value {
                            ... on kontent_item_quiz__result {
                              id
                              elements {
                                code {
                                  value
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                image {
                  value {
                    width
                    url
                    type
                    size
                    name
                    height
                    description
                  }
                }
                question {
                  value
                }
              }
            }
          }
        }
      }
      system {
        language
      }
    }
  }
`;
