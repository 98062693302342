/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import './Button.scss';

const Button = ({
  type,
  theme,
  size,
  icon,
  iconPosition,
  disabled,
  onPress,
  children,
  fluid,
  className,
  href,
}) => (
  <>
    {type === 'link' && (
      <a
        href={href}
        aria-label={children}
        title={children}
        className={`button ${theme} size-${size} ${icon ? ` icon-${icon}` : ''}${iconPosition ? ` icon-position-${iconPosition}` : ''} ${fluid && 'fluid'} ${className}`}
        onClick={() => { onPress(); }}
      >
        {children}
      </a>
    )}
    {(type === 'button' || type === 'submit') && (
      <button
        type={type}
        aria-label={children}
        title={children}
        className={`button ${theme} size-${size} ${icon ? ` icon-${icon}` : ''}${iconPosition ? ` icon-position-${iconPosition}` : ''} ${fluid && 'fluid'} ${className}`}
        disabled={disabled}
        onClick={() => { onPress(); }}
        onKeyDown={(ev) => {
          if (ev.key === 'Enter') {
            onPress();
          }
        }}
        tabIndex={0}
      >
        {children}
      </button>
    )}
  </>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  onPress: PropTypes.func,
  size: PropTypes.string,  
  theme: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  iconPosition: PropTypes.string,
  className: PropTypes.string,
  fluid: PropTypes.bool,
  href: PropTypes.string,
};

Button.defaultProps = {
  type: 'button',
  onPress: () => {},
  size: 'standard',
  theme: 'primary',
  disabled: false,
  icon: null,
  iconPosition: null,
  fluid: false,
  className: undefined,
  href: '#',
};

export default Button;
