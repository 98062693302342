import React from 'react';
import PropTypes from 'prop-types';
// import { CSSTransitionGroup } from 'react-transition-group';
import QuestionCount from '../QuestionCount/QuestionCount';
import AnswerOption from '../AnswerOption/AnswerOption';
import QuestionProgress from '../QuestionProgress/QuestionProgress';
import './Quiz.scss';

const Quiz = (props) => {
  function renderAnswerOptions(key) {
    return (
      <AnswerOption
        key={key.content}
        answerContent={key.content}
        answerType={key.type}
        answer={props.answer}
        questionId={props.questionId}
        onAnswerSelected={props.onAnswerSelected}
      />
    );
  }

  return (
    <div className={`quiz quiz--question-${props.questionId}`} key={props.questionId}>
      <QuestionCount counter={props.questionId} total={props.questionTotal} tpl={props.questionCountTpl} />
      <QuestionProgress counter={props.questionId} total={props.questionTotal} />
      <div className="question-details">
        <div className="question-image">
          <img src={props.question.image} alt={props.question.question} />
        </div>
        <h2>{props.question.question}</h2>
        <ul className="answer-options">
          {props.answerOptions.map(renderAnswerOptions)}
        </ul>
      </div>
    </div>
  );
}

Quiz.propTypes = {
  answer: PropTypes.string.isRequired,
  answerOptions: PropTypes.array.isRequired,
  question: PropTypes.shape({
    question: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }).isRequired,
  questionId: PropTypes.number.isRequired,
  questionTotal: PropTypes.number.isRequired,
  onAnswerSelected: PropTypes.func.isRequired,
  questionCountTpl: PropTypes.string.isRequired,
};

export default Quiz;
