import React from 'react';
import PropTypes from 'prop-types';
import BannerHeader from '../BannerHeader/BannerHeader';
// import './QuestionCount.scss';

const QuestionCount = ({
  tpl,
  counter,
  total
}) => {
  return (
    <BannerHeader className="question-count">
      { tpl.replace('{COUNT}', counter).replace('{TOTAL}', total) }
    </BannerHeader>
  );
};

QuestionCount.propTypes = {
  counter: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  tpl: PropTypes.string.isRequired
};

export default QuestionCount;
