import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
} from 'react-share';
import BannerHeader from '../BannerHeader/BannerHeader';
import Button from '../Button/Button';
import './Result.scss';

const Result = ({
  quizResult,
  sentEmail,
  title,
  shareTitle,
  finalInstructions,
  onBack,
  formReAsk,
  formValuePropImageUrl,
  formValuePropImageAlt,
  formReAskTitle,
  formReAskMessage,
  formReAskCTA,
}) => {
  const SITE_URL = R.pathOr('#', ['GATSBY_SITE_URL'], process.env);
  return (
    <div className="result">
      <header>
        <BannerHeader className="result-banner">
          {title}
        </BannerHeader>
      </header>
      <div className="result-details">
        <div className="image-container">
          <img src={quizResult.dishImage} alt={quizResult.dishName} />
        </div>
        <div className="result-details--information">
          <h2>{quizResult.dishName}!</h2>
          <p>{quizResult.dishDescription}</p>
          {sentEmail && (
            <p>
              {finalInstructions}
            </p>
          )}
        
          <section className="share-links">
            <header>
              <h4>{shareTitle}</h4>
            </header>
            <div>
              <FacebookShareButton url={SITE_URL}>
                <FacebookIcon
                  iconFillColor="#666666"
                  bgStyle={{ fill: 'transparent' }}
                  size={48}
                />
              </FacebookShareButton>
              <TwitterShareButton url={SITE_URL}>
                <TwitterIcon
                  iconFillColor="#666666"
                  bgStyle={{ fill: 'transparent' }}
                  size={48}
                />
              </TwitterShareButton>
            </div>
          </section>

          {!sentEmail && formReAsk && (
            <section className="value-prop-ask">
              <header>
                <h4>{formReAskTitle}</h4>
              </header>
              <div>
                {formValuePropImageUrl != null && (
                  <img src={formValuePropImageUrl} alt={formValuePropImageAlt} />  
                )}
                <div>
                  <p>{formReAskMessage}</p>
                  <Button
                    onPress={() => {
                      onBack();
                    }}
                  >
                    {formReAskCTA}
                  </Button>
                </div>
              </div>
            </section>
          )}
        </div>
      </div>
    </div>
  );
};

Result.propTypes = {
  quizResult: PropTypes.shape({
    dishName: PropTypes.string.isRequired,
    dishDescription: PropTypes.string.isRequired,
    dishImage: PropTypes.string.isRequired,
  }).isRequired,
  sentEmail: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  shareTitle: PropTypes.string.isRequired,
  finalInstructions: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
  formReAsk: PropTypes.bool.isRequired,
  formValuePropImageUrl: PropTypes.string,
  formValuePropImageAlt: PropTypes.string,
  formReAskTitle: PropTypes.string,
  formReAskMessage: PropTypes.string,
  formReAskCTA: PropTypes.string,
};

Result.defaultProps = {
  formValuePropImageUrl: null,
  formValuePropImageAlt: '',
  formReAskTitle: '',
  formReAskMessage: '',
  formReAskCTA: '',
};

export default Result;
