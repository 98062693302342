import PropTypes from 'prop-types';
import React from 'react';
// import ReactMarkdown from 'react-markdown';
import WorldVisionLogo from '../WorldVisionLogo/WorldVisionLogo';
import Container from '../Container/Container';
import './PageFooter.scss';

const PageFooter = ({
  copyright,
  address,
  privacyPolicyUrl,
  privacyPolicyLabel,
  termsAndConditionsUrl,
  termsAndConditionsLabel,
}) => (
  <footer className="page-footer">
    <section className="footer-nav">
      <Container>
        <div className="footer-nav-wrapper">
          <div className="footer-nav-details">
            <WorldVisionLogo />

            <div className="footer-nav-details-text">
              <p>
                {address}
              </p>
            </div>
          </div>

          <nav className="footer-menu">
            <ul className="footer-menu-list">
                <li><a href={privacyPolicyUrl}>{ privacyPolicyLabel }</a></li>
                <li><a href={termsAndConditionsUrl}>{ termsAndConditionsLabel }</a></li>
            </ul>
          </nav>
        </div>
      </Container>
    </section>

    <div className="footer-bottom">
      <Container>
        <div className={`footer-bottom-wrapper`}>
          <div className="footer-bottom-text">
            <p>
              {copyright}
            </p>
          </div>
        </div>
      </Container>
    </div>
  </footer>
);

PageFooter.propTypes = {
  copyright: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  privacyPolicyUrl: PropTypes.string.isRequired,
  privacyPolicyLabel: PropTypes.string.isRequired,
  termsAndConditionsUrl: PropTypes.string.isRequired,
  termsAndConditionsLabel: PropTypes.string.isRequired,
};

export default PageFooter;
